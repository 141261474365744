import React, { useState, useEffect } from "react";
import { TokenData } from "../interfaces/TokenData";
import { getTokenData } from '../services/tokenDataService';

interface TemplateProps {
    children: React.ReactNode;
}

const Layout: React.FC<TemplateProps> = ({ children }) => {
    const [tokenData, setTokenData] = useState<TokenData>();

    useEffect(() => {
        const fetchData = async () => {
            setTokenData(getTokenData())
        }
        fetchData();
        return () => { }
    }, []);

    if (!tokenData) return (<div>'Loading...'</div>)

    return (
        <div>
            {children}
        </div >
    );
};

export default Layout;
