import React from "react";
import logo from "./logo.svg";
import "./assets/style.css";
import "./assets/fontawesome/css/all.min.css";
import "./App.css";

import Home from "./components/Home";

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

const App: React.FC = () => {

    const tokenData = {
        contractAddress: "",
        twitterUrl: "",
        telegramUrl: "",
        websiteUrl: "",
    }

    return (
        <>
            <Router>
                <Routes>
                    <Route path="/" element={<Home />} />
                </Routes>
            </Router>
        </>
    );
};

export default App;
