import React, { useState, useEffect, useRef } from "react";
import Layout from "../components/Layout";
import { TokenData } from "../interfaces/TokenData";
import { getTokenData } from '../services/tokenDataService';
import catchupImg from '../imgs/catchup.png';
import xlogo from '../imgs/x.png';
import pumplogo from '../imgs/pump.png';
import { randomInt } from "crypto";

const baseSize = 350;
const increasedSize = 390;

const Home: React.FC = () => {
    const [tokenData, setTokenData] = useState<TokenData>();
    const [isPlaying, setIsPlaying] = useState(false);
    const [catWidth, setCatWidth] = useState(baseSize);
    const [xSize, setxSize] = useState(100);
    const [pumpSize, setpumpSize] = useState(100);
    const [playerCount, setPlayerCount] = useState(0);
    const audioRef = useRef<HTMLAudioElement | null>(null);
    const [players, setPlayers] = useState<any>([]);

    const addPlayer = (source : string) => {
      setPlayers([...players, { id: Date.now(), src: source }]);
    };
  
    const removePlayer = (id : any) => {
      setPlayers(players.filter((player: { id: any; }) => player.id !== id));
    };
  
    const updatePlayerSource = (id: any, src: any) => {
      setPlayers(players.map((player: { id: any; }) => player.id === id ? { ...player, src } : player));
    };

    // Load pinned items from local storage
    useEffect(() => {
        setTokenData(getTokenData());
        const playAudio = async () => {
            try {
                setInterval(async () => {
                    if (audioRef.current) {
                        try {
                            await audioRef.current.play();
                            setIsPlaying(true);
                        } catch (error) {
                            console.log('Autoplay prevented:', error);
                        }
                    }
                }, 1000);
            } catch (error) {
                console.log('Autoplay prevented:', error);
            }
        };

        // Call the function to attempt playback
        playAudio();

        return () => {
        }
    }, []);

    const mouseOver = () => {
        setCatWidth(increasedSize);
    }

    const mouseLeave = () => {
        setCatWidth(baseSize);
    }

    const playSound = () => {
        const files = [
            "./sounds/meow (1).mp3",
            "./sounds/meow (2).mp3",
            "./sounds/meow (3).mp3",
            "./sounds/meow (4).mp3",
            "./sounds/meow (5).mp3",
            "./sounds/meow (6).mp3",
            "./sounds/meow (7).mp3",
            "./sounds/meow (8).mp3",
            "./sounds/meow (9).mp3",
            "./sounds/meow (10).mp3",
            "./sounds/meow (11).mp3",
        ]

        const rand = Math.floor(Math.random() * (files.length));

        addPlayer(files[rand]);
    }

    if (!tokenData) return <div>loading</div>

    return (
        <Layout>
            <div data-name="inner-content" style={{ display: "flex", justifyContent: "center", marginBottom: "10px", flexFlow: "wrap", height: "140px" }}>
                <div>
                    <a href={tokenData.photonUrl}>
                        <img onMouseOver={() => setpumpSize(120)} onMouseLeave={() => setpumpSize(100)} style={{ width: pumpSize +"px"}} src={pumplogo}></img>
                    </a>
                </div>
                <div>
                    <a href={tokenData.twitterUrl} target="_blank">
                        <img onMouseOver={() => setxSize(120)} onMouseLeave={() => setxSize(100)} style={{ width: xSize +"px"}} src={xlogo}></img>
                    </a>
                </div>
            </div>
            <div data-name="inner-content" style={{ display: "flex", justifyContent: "center", marginBottom: "10px", flexFlow: "wrap" }}>
                <div className="cat-img">
                    <img onClick={() => playSound()} onMouseOver={() => mouseOver()} onMouseLeave={() => mouseLeave()} style={{ width: catWidth +"px"}} src={catchupImg}></img>
                </div>
            </div>
            <div id="playerHolder"></div>
            {players.map((player: { id: React.Key | null | undefined; src: string | undefined; }) => (
                <div key={player.id}>                
                    <audio autoPlay>
                        <source src={player.src} />
                    </audio>
                </div>
            ))}
        </Layout >
    );
};

export default Home;
